import { useEffect } from 'react'
import AOS from 'aos'
import 'aos/dist/aos.css'
import { H2 } from '../../../components/elements/H2'
import { useTranslation } from 'react-i18next'
import { Layout, Services, ServiceCard, Img, ServiceTitle, ServiceDescription } from './Style'

export const ServicesOverview = () => {
  const { t } = useTranslation('home')

  useEffect(() => {
    AOS.init({
      duration: 1200,
    })
  }, [])

  const services = t('servicesOverview.imgContent', { returnObjects: true })
  return (
    <Layout>
      <H2>{t('servicesOverview.title')}</H2>
      <Services>
        {services.map((service, index) => (
          <ServiceCard
            key={index}
            // Use the index to alternate animation directions and delay for a nice staggered effect
            data-aos={index % 2 === 0 ? 'fade-right' : 'fade-left'}
            data-aos-offset='200' // Trigger animation when the element is within 200px of the viewport
            data-aos-delay={`${index * 100}`} // Each card's animation delay increases by 100ms
            data-aos-once='true' // Animation will only happen once when scrolling down
          >
            <Img src={service.img} alt={service.text} />
            <ServiceTitle>{service.text}</ServiceTitle>
            <ServiceDescription>{service.description}</ServiceDescription>
          </ServiceCard>
        ))}
      </Services>
    </Layout>
  )
}
