import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useLocation } from 'react-router-dom'

const supportedLanguages = ['en', 'fr'] // Extend this list when needed

export const useLocalizedPath = () => {
  const { i18n } = useTranslation()
  const navigate = useNavigate()
  const location = useLocation()

  useEffect(() => {
    const pathSegments = location.pathname.split('/').filter(Boolean)
    const detectedLang = supportedLanguages.includes(pathSegments[0]) ? pathSegments[0] : i18n.language

    // Change the i18next language if it's different from the detected language
    if (i18n.language !== detectedLang) {
      i18n.changeLanguage(detectedLang)
    }

    // Construct the expected path based on the current language and remaining path segments
    const expectedPath = `/${detectedLang}/${pathSegments.slice(1).join('/')}`
    if (location.pathname !== expectedPath && supportedLanguages.includes(detectedLang)) {
      navigate(expectedPath, { replace: true })
    }
  }, [i18n, navigate, location])
}
