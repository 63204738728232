import styled, { keyframes } from 'styled-components'

export const Container = styled.div`
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 50rem;
  margin-left: auto;
  margin-right: 4vw;
`

export const TitleRef = styled.h1`
  font-size: 2.5rem;
  color: ${({ theme }) => theme.colors.primary};
  margin: 0 auto;
  text-align: center;
`

export const SubheadingRef = styled.h2`
  font-size: 1.6rem;
  margin-bottom: 2rem;
  text-align: center;
`

export const ButtonRef = styled.button`
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 0.5rem 1rem;
  font-size: 1.5rem;
  cursor: pointer;
  transition: 0.3s;
  margin: 0 auto;
  display: block;
  text-align: center;
  text-decoration: none;
`

export const Layout = styled.div`
  z-index: 1;
  background: #f9f9f9; // Light background for a clean look
  background: url('/img/services/truck.webp') no-repeat center center;
  background-size: cover;
  min-height: 85vh;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 15vh;

  .error-message {
    margin-bottom: 2rem;
    font-size: 1.4rem;
  }

  &::before {
    // ... existing styles ...
  }

  @media screen and (max-width: 768px) {
    background-position: 0;
  }
`

export const StyledContainer = styled(Container)`
  background: white;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  max-width: 400px;
  width: 100%;
`

export const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 40rem;

  input {
    font-size: 1.6rem;
    padding: 0.8rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-bottom: 1rem;
  }

  .forgot-password {
    color: ${({ theme }) => theme.colors.primary};
    text-decoration: underline;
    cursor: pointer;
    align-self: flex-end;
    margin-bottom: 1rem;
  }
`

export const StyledLoadingIndicator = styled.div`
  border: 8px solid #f3f3f3;
  border-radius: 50%;
  border-top: 8px solid #007bff;
  width: 60px;
  height: 60px;
  animation: spin 1s linear infinite;
  margin: 0 auto;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }
`

export const Title = styled(TitleRef)`
  font-size: 5rem;
  color: ${({ theme }) => theme.colors.primary};
  margin: 0 auto;
  text-align: center;

  @media screen and (max-width: 768px) {
    font-size: 4rem;
  }
`

export const Subheading = styled(SubheadingRef)`
  color: ${({ theme }) => theme.colors.primary};
  font-size: 2.4rem;
  margin-bottom: 2rem;
  text-align: center;

  @media screen and (max-width: 768px) {
    font-size: 1.8rem;
  }
`

export const Subtitle = styled.p`
  font-size: 1.6rem;
  margin-bottom: 2rem;
`

export const Button = styled(ButtonRef)`
  margin: auto;
  margin-top: 1rem;
  width: 100%;
  max-width: 26rem;
  padding: 1rem;
  background: ${({ theme }) => theme.colors.primary};
`

export const LoadingIndicator = styled(StyledLoadingIndicator)`
  margin: 0 auto;
`
