import React from 'react'

import img from '../../../app/media/img/fast-rail-delivery.webp'
import logo from '../../../app/media/img/logo-full-colors.png'

import { ImageAd } from '.'
import { useTranslation } from 'react-i18next'

export const ImageAd2 = () => {
  const { t } = useTranslation('home')
  return (
    <ImageAd
      src={img}
      // Layout={Layout}
      logo={logo}
      title={t('imageAd2.title')}
      description={t('imageAd2.content')}
      left='62%'
      bottom='30%'
    />
  )
}
