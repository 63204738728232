import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

const Section = styled.div`
  padding: 20px;
`

const Title = styled.h4`
  margin-bottom: 1rem;
  color: #ccc;
`

const Content = styled.p`
  color: #999;
`

export const AboutUs = () => {
  const { t } = useTranslation('footer')

  return (
    <Section>
      <Title>{t('aboutUs.title')}</Title>
      <Content>{t('aboutUs.content')}</Content>
    </Section>
  )
}
