import styled from 'styled-components'
import { Col as ColRef } from '../../../components/layouts/Col'

export const Col = styled(ColRef)`
  position: absolute;
  bottom: 0%; // Adjust based on your design needs
  /* left: 8%; */
  /* right: 5%; */
  text-align: left;
  color: #ffffff; // Assuming white text color for visibility
  max-width: 35vw;
  margin: 0 5vw;

  @media screen and (max-width: 768px) {
    max-width: 80vw;
    margin: 0 10vw;
    bottom: 0%;
  }
`

export const Layout = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  /* background: url('/img/hero-img.png'); */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  height: 65vh;
  margin-top: 15vh;
`

// ref should come from boilerplate
export const H1Ref = styled.h1`
  font-size: 6rem;
  font-weight: 500;
  margin: 0;
  margin-top: 2rem;
  color: ${({ theme, color }) => color || theme.colors.primary};

  @media screen and (max-width: 768px) {
    font-size: 4rem;
    text-align: left;
    margin-right: auto;
  }
`

// used here:
export const H1 = styled(H1Ref)`
  color: ${({ theme }) => theme.colors.white};
  line-height: 0.8;
`

export const Subheading = styled.span`
  display: block;
  font-size: 4.5rem;
  font-weight: 300;
  margin: 0;
  margin-top: 2.5rem;
  color: ${({ theme }) => theme.colors.white};

  @media screen and (max-width: 768px) {
    font-size: 3rem;
    text-align: left;
  }
`

const Image = styled.img`
  width: 100%;
  height: auto;
`
