import styled, { css } from 'styled-components'
import ButtonRef from '../Button'

const Button = styled(ButtonRef)`
  padding: 8px 15px;
  border-radius: 4px;
  font-size: 16px;
  border: 1px solid transparent;
  cursor: pointer;
  transition: background-color 0.3s, border-color 0.3s;

  /* PRIMARY */
  ${(props) =>
    props.primary &&
    css`
      background-color: #007bff;
      color: white;
      border-color: #007bff;

      &:hover {
        background-color: #0069d9;
        border-color: #0062cc;
      }
    `}

  /* SECONDARY */
  ${(props) =>
    props.secondary &&
    css`
      background-color: white;
      color: #007bff;
      border-color: #007bff;

      &:hover {
        background-color: #f8f9fa;
        border-color: #0062cc;
      }
    `}
`

export default Button
