import React from 'react'
import { useNavigate } from 'react-router-dom'
import 'react-responsive-carousel/lib/styles/carousel.min.css' // requires a loader
import { Carousel as CarouselRef } from 'react-responsive-carousel'
import img1 from '../../../../app/media/img/business-lady.webp'
import img2 from '../../../../app/media/img/cargo-transport-plane.webp'
import img3 from '../../../../app/media/img/3.jpg'
import styled from 'styled-components'
import ButtonRef from '../../../../components/elements/Button'
import { Col } from '../Style'
import { useTranslation } from 'react-i18next'

let imgs = [img1, img2, img3]

export const Button = styled(ButtonRef)`
  font-size: 1.5rem;
  font-weight: 500;
  background: white;
  color: ${({ theme }) => theme.colors.primary};
  letter-spacing: 0.5px;
  width: 100%;
  max-width: 45rem;

  @media (max-width: 768px) {
    font-size: 1.2rem;
  }
`

export const StyledCarousel = styled(CarouselRef)`
  width: 100%;
  height: 100%;

  .carousel-slider {
    height: 100%;

    .slider-wrapper {
      height: 100%;
    }

    ul.slider {
      height: 100%;
    }
  }
`

const SlideContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;

  img {
    height: 100%;
    object-fit: cover;
  }

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to right, rgba(36, 56, 89, 0.8) 0%, rgba(0, 0, 255, 0) 50%, transparent 100%);
    // Apply a blur effect to the left side by using a wider element and clipping it
    /* filter: blur(8px); */
    /* transform: translateX(-25%); */
    width: 125%;
  }
`

const Slogan = styled.h1`
  font-size: 5rem;
  font-weight: 600;
  margin: 0;
  color: white;

  @media (max-width: 768px) {
    font-size: 3.25rem;
  }
`

const P = styled.p`
  font-size: 2.5rem;
  font-weight: 500;
  color: white;

  @media (max-width: 768px) {
    font-size: 1.5rem;
  }
`

export const Carousel = () => {
  const { t } = useTranslation('home')
  const navigate = useNavigate()
  return (
    <StyledCarousel
      useKeyboardArrows
      infiniteLoop
      selectedItem={0}
      autoPlay
      showThumbs={false}
      showStatus={false}
      showIndicators={true}
      interval={5000}
      stopOnHover={false}
      swipeable={true}
      showArrows={false}
      emulateTouch={true}
      dynamicHeight={false}
      autoFocus={true}
      centerMode={true}
      centerSlidePercentage={100}
      thumbWidth={100}
      preventMovementUntilSwipeScrollTolerance={true}
      swipeScrollTolerance={50}>
      {t('heroCarousel', { returnObjects: true }).map((item, index) => (
        <SlideContainer key={index}>
          <img src={imgs[index]} alt='' />
          <Col>
            <Slogan>{item.slogan}</Slogan>
            <P>{item.description}</P>
            <Button onClick={() => navigate(item.buttonLink)}>{item.buttonText}</Button>
          </Col>
        </SlideContainer>
      ))}

      {/* <SlideContainer>
        <img src={img1} alt='' />
        <Col>
          <Slogan>{t('hero.title')}</Slogan>
          <P>Notre mission va au-delà du transport. Elle consiste à vous fournir la tranquillité d'esprit.</P>
          <Button>DÉCOUVRIR LA DIFFÉRENCE ACCESS</Button>
        </Col>
      </SlideContainer>
      <SlideContainer>
        <img src={img2} alt='' />
        <Col>
          <Slogan>Explore New Horizons</Slogan>
          <P>Embark on a journey to discover new possibilities and uncover hidden opportunities.</P>
          <Button>START YOUR ADVENTURE</Button>
        </Col>
      </SlideContainer>
      <SlideContainer>
        <img src={img3} alt='' />
        <Col>
          <Slogan>Innovation for Your Convenience</Slogan>
          <P>Experience the future of technology today and stay ahead of the curve.</P>
          <Button>EMBRACE INNOVATION</Button>
        </Col>
      </SlideContainer> */}
    </StyledCarousel>
  )
}
