import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { Layout, PreTitle, Title, Block, BlockTitle, Text, Button, OuterText, Col } from './Style'
import { GridRow } from '../../../components/layouts/GridRow'

export const CTASecondary = () => {
  const { t: originalT } = useTranslation('home')
  const t = (key, ...args) => originalT(`ctasecondary.${key}`, ...args)

  const navigate = useNavigate()

  return (
    <Layout>
      <PreTitle>{t('preTitle')}</PreTitle>
      <Title>{t('title')}</Title>
      <GridRow col='2' gap='1rem' mobile>
        <Col>
          <Block>
            <BlockTitle>{t('instantQuoteBlockTitle')}</BlockTitle>
            <Text>{t('instantQuoteText')}</Text>
          </Block>
          <Button onClick={() => navigate(t('supplyChainQuoteButtonLink'))}>{t('instantQuoteButton')}</Button>
        </Col>
        <Col>
          <Block colored>
            <BlockTitle>
              {t('supplyChainQuoteBlockTitle')}
              <br /> <span className='small italic'>{t('supplyChainQuoteBlockSubtitle')}</span>
            </BlockTitle>
            <Text>{t('supplyChainQuoteText')}</Text>
          </Block>
          <Button colored onClick={() => navigate(t('supplyChainQuoteButtonLink'))}>
            {t('supplyChainQuoteButton')}
          </Button>
        </Col>
      </GridRow>
      <OuterText>{t('outerText')}</OuterText>
    </Layout>
  )
}
