import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { NavLink, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { Row } from '../../../../components/layouts/Row'
import { CTAButton } from '../Style'
import { Logo } from '../../header/Style'

import logo from '../../../../app/media/img/logo-darkblue.png'
import { Col } from '../../../../components/layouts/Col'
import { headerLinks as defaultFooterLinks } from '../../header/headerConfig'

const TemplateContainer = styled.div`
  display: flex;
  flex-direction: column; /* Stack sections on mobile */
  align-items: center; /* Center content on mobile */
  padding: 2rem 6rem;
  gap: 2rem;
  width: 100%;

  @media (min-width: 768px) {
    /* Adjust for tablets and larger devices */
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  @media (max-width: 1024px) {
    padding: 0;
  }
`

const Section = styled.div`
  display: flex;
  flex-direction: column; /* Ensure vertical layout for sections */
  align-items: center; /* Center content within each section on mobile */
  min-width: 130px; /* Minimum width for content */
  margin-bottom: 20px;

  @media (min-width: 768px) {
    flex: 1;
    align-items: flex-start; /* Align to start for larger devices */
  }
`

const Title = styled.h4`
  color: #ccc;
  font-size: 2rem;
  font-weight: 600;
`

const P = styled.p`
  text-align: center;

  @media (min-width: 768px) {
    text-align: left;
  }
`

const List = styled.ul`
  list-style: none;
  padding: 0;
  text-align: center; /* Center list items on mobile */

  @media (min-width: 768px) {
    text-align: left; /* Align text to the left on larger screens */
  }
`

const ListItem = styled.li`
  margin: 10px 0;
  font-size: 3.4rem;
  font-weight: 600;

  @media (max-width: 768px) {
    font-size: 2.6rem;
  }
`

const CustomNavLink = styled(NavLink)`
  color: white;
  color: ${({ theme }) => theme.colors.text};
  text-decoration: none;

  &:hover {
    color: #045cac;
  }
`

const SocialMediaIcons = styled.div`
  display: flex;
  justify-content: center; /* Center social icons on mobile */
  margin-top: 1rem; /* Add some spacing on the top */

  @media (min-width: 768px) {
    justify-content: flex-start; /* Align icons to the start on larger screens */
  }
`

const SocialLink = styled.a`
  color: ${({ theme }) => theme.colors.primary};
  margin-right: 15px;
  margin-left: auto;
  margin-top: auto;
  font-size: 24px;

  &:hover {
    color: #1b75bc;
  }

  @media (max-width: 768px) {
    margin-left: 0;
    margin: 0 auto;
  }
`

const ContactInfo = styled.p`
  margin-top: 6rem;
  margin-left: auto;
  text-align: right;

  @media (max-width: 768px) {
    text-align: center;
    margin-left: 0;
  }
`

export const BasicLinks = () => {
  const [footerLinks, setFooterLinks] = useState([])
  const navigate = useNavigate()

  const { i18n } = useTranslation()
  const { t } = useTranslation('common')

  // const navigate = useNavigate()
  // const location = useLocation()

  const pathMap = {
    en: {
      '/notre-equipe': '/our-team',
      '/nos-services': '/our-services',
      // Add other mappings as necessary
    },
    fr: {
      '/our-team': '/notre-equipe',
      '/our-services': '/nos-services',
      // Reverse mappings for completeness
    },
  }

  const adjustLinksForLanguage = (links, lang) => {
    return links.map((link) => {
      // Directly return the path for the homepage
      if (link.path === '/') {
        return { ...link, path: lang === 'en' ? '/' : `/${lang}` } // Adjust for non-English home paths
      }

      // Check for a path mapping based on the current language
      const mappedPath = pathMap[lang][link.path]

      // Construct the new path, using the mapped path if available
      const newPath = mappedPath ? `/${lang}${mappedPath}` : `/${lang}${link.path}`

      return { ...link, path: newPath }
    })
  }
  useEffect(() => {
    const currentLang = i18n.language.split('-')[0] // Assuming your language codes are 'en', 'fr', etc.
    const adjustedLinks = adjustLinksForLanguage([...defaultFooterLinks], currentLang)
    setFooterLinks(adjustedLinks)
  }, [i18n.language])

  return (
    <>
      <TemplateContainer>
        <Row space-between='justify'>
          <Section>
            {/* <Title>{t('basicLinks.title')}</Title> */}

            <List>
              {footerLinks.map(({ path, key }) => (
                <ListItem key={path}>
                  <CustomNavLink to={path}>{t(key)}</CustomNavLink>
                </ListItem>
              ))}
            </List>
            {/* <ListItem>
                <CustomNavLink to='/'>{t('basicLinks.home')}</CustomNavLink>
              </ListItem>
              <ListItem>
                <CustomNavLink to='/about'>{t('basicLinks.team')}</CustomNavLink>
              </ListItem>
              <ListItem>
                <CustomNavLink to='/services'>{t('basicLinks.services')}</CustomNavLink>
              </ListItem>
              <ListItem>
                <CustomNavLink to='/contact'>{t('basicLinks.contact')}</CustomNavLink>
              </ListItem>
              <ListItem>
                <CustomNavLink to='/contact'>{t('basicLinks.login')}</CustomNavLink>
              </ListItem> */}

            <CTAButton colored onClick={() => navigate(t('nav.ctaButtonLink'))}>
              {t('nav.ctaButtonText')}
            </CTAButton>
          </Section>
          {/* 
        <Section>
          <Title>{t('contactInfo.title')}</Title>
          <p>{t('contactInfo.address')}</p>
          <p>{t('contactInfo.phone')}</p>
          <p>{t('contactInfo.email')}</p>
        </Section> */}
          <Col width='fit-content' height='unset' justify='flex-start' align='space-between'>
            <Logo src={logo} width='40rem' />
            <ContactInfo>
              <b>{t('nav.phoneText')}</b> 450-625-7433
              <br />
              sac@accesscourtier.com
            </ContactInfo>
            {/* <SocialLink href={t('socialFollow.facebookLink')} target='_blank' rel='noopener noreferrer'>
              <svg width='55' height='55' viewBox='0 0 55 55' fill='none' xmlns='http://www.w3.org/2000/svg'>
                <path
                  d='M50.7031 0H4.29688C1.92285 0 0 1.92285 0 4.29688V50.7031C0 53.0771 1.92285 55 4.29688 55H50.7031C53.0771 55 55 53.0771 55 50.7031V4.29688C55 1.92285 53.0771 0 50.7031 0ZM33.5693 22.8379L33.2578 26.9521H29.0361V41.2393H23.708V26.9521H20.8613V22.8379H23.708V20.0771C23.708 18.8633 23.7402 16.9834 24.6211 15.8125C25.5557 14.5771 26.834 13.7393 29.0361 13.7393C32.624 13.7393 34.1279 14.2549 34.1279 14.2549L33.4189 18.4658C33.4189 18.4658 32.2373 18.1221 31.1309 18.1221C30.0244 18.1221 29.0361 18.5195 29.0361 19.626V22.8379H33.5693Z'
                  fill='#314263'
                />
              </svg>
            </SocialLink> */}
          </Col>
        </Row>
      </TemplateContainer>
      <span style={{ fontSize: '1.15rem', fontWeight: 'bold', textAlign: 'center' }}>
        © 2024 Access {t('nav.copyrights.part1')}{' '}
        <a
          href='https://www.sor-seo.com'
          target='_blank'
          rel='noopener noreferrer'
          style={{ color: 'black', fontWeight: 'normal' }}
          aria-label='Visit SOR SEO for design, development and devshop agency services'>
          | {t('nav.copyrights.part2')} SOR SEO
        </a>
      </span>
    </>
  )
}
