import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

const Section = styled.div`
  padding: 20px;
`

const Title = styled.h4`
  margin-bottom: 1rem;
  color: #ccc;
`

const SocialLinks = styled.div`
  display: flex;
`

const SocialLink = styled.a`
  margin-right: 15px;
  color: #333;

  &:hover {
    color: #1b75bc;
  }
`

export const SocialFollow = () => {
  const { t } = useTranslation('footer')

  return (
    <Section>
      <Title>{t('socialFollow.title')}</Title>
      <SocialLinks>
        <SocialLink href={t('socialFollow.facebookLink')} target='_blank' rel='noopener noreferrer'>
          <i className='fab fa-facebook-f'></i>
        </SocialLink>
        <SocialLink href={t('socialFollow.twitterLink')} target='_blank' rel='noopener noreferrer'>
          <i className='fab fa-twitter'></i>
        </SocialLink>
        <SocialLink href={t('socialFollow.instagramLink')} target='_blank' rel='noopener noreferrer'>
          <i className='fab fa-instagram'></i>
        </SocialLink>
        <SocialLink href={t('socialFollow.linkedinLink')} target='_blank' rel='noopener noreferrer'>
          <i className='fab fa-linkedin-in'></i>
        </SocialLink>
      </SocialLinks>
    </Section>
  )
}
