import React, { useState } from 'react'

import { ErrorMessage, FormikProvider, useFormik } from 'formik'
import * as yup from 'yup'
import { useNavigate } from 'react-router-dom'
import { Layout, Container, Button, StyledForm, Subheading, Title, LoadingIndicator } from './Style'

import axios from 'axios'

import { useTranslation } from 'react-i18next'

export const ClientLogin = () => {
  const [showSpinner, setShowSpinner] = useState(false)
  const [formErrorMessage, setFormErrorMessage] = useState(null)
  const [responseMessage, setResponseMessage] = useState(null)
  const navigate = useNavigate()

  const { t } = useTranslation('clientLogin')

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: yup.object({
      email: yup.string().email('Veuillez entrer une adresse email valide.').required('Veuillez entrer votre adresse email.'),
      password: yup.string().required('Veuillez entrer votre mot de passe.'),
    }),
    onSubmit: async (values) => {
      setShowSpinner(true)

      try {
        const response = await axios.post('/api/auth/login', values)
        const data = response.data

        if (data.success) {
          setResponseMessage(data.message)
          setShowSpinner(false)
          setFormErrorMessage(null)
          navigate('/app/dashboard')
        } else {
          setResponseMessage(null)
          setShowSpinner(false)
          setFormErrorMessage(data.message)
        }
      } catch (error) {
        console.log(error)
        setResponseMessage(null)
        setShowSpinner(false)
        setFormErrorMessage("Une erreur s'est produite. Veuillez réessayer.")
      }
    },
  })

  return (
    <Layout>
      <Container>
        <Title>{t('clientPortal.title')}</Title>
        <Subheading>{t('clientPortal.welcomeMessage')}</Subheading>

        <FormikProvider value={formik}>
          <StyledForm onSubmit={formik.handleSubmit}>
            {formErrorMessage && <p>{formErrorMessage}</p>}

            <input type='email' name='email' onChange={formik.handleChange} onBlur={formik.handleBlur} placeholder={t('clientPortal.fields.emailPlaceholder')} value={formik.values.email} />
            <ErrorMessage name='email' component='div' className='error-message' />

            <input
              type='password'
              name='password'
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              placeholder={t('clientPortal.fields.passwordPlaceholder')}
              value={formik.values.password}
            />
            <ErrorMessage name='password' component='div' className='error-message' />

            {/* <span className='forgot-password'>{t('clientPortal.forgotPassword')}</span> */}

            {showSpinner ? <LoadingIndicator /> : <Button type='submit'>{t('clientPortal.signInButton')}</Button>}
          </StyledForm>
        </FormikProvider>

        {responseMessage && <p>{responseMessage}</p>}
      </Container>
    </Layout>
  )
}

export default ClientLogin
