import { Layout } from './Style'
import { Carousel } from './Carousel'

export const Hero = () => {
  return (
    <Layout>
      <Carousel />
    </Layout>
  )
}
