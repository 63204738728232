import styled from 'styled-components'
import { GridRow } from '../../../components/layouts/GridRow'

export const Layout = styled.div`
  position: relative;
  width: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  background: linear-gradient(rgba(1, 20, 46, 1), rgba(1, 12, 27, 1));
`

export const Image = styled.img`
  position: relative;
  width: 100%;
  height: 100vh;
  object-fit: contain;
  object-position: center;

  transform: scale(1.15);

  @media (max-width: 768px) {
    transform: scale(1.1);
    height: auto;
  }
`

export const FloatingDiv = styled.div`
  position: absolute;
  bottom: ${({ bottom }) => bottom || '20%'};
  left: ${({ left }) => left || '20%'};
  right: ${({ right }) => right};
  transform: translate(-50%, -50%);
  text-align: left;
`

export const ContentDiv = styled.div`
  position: relative;
  text-align: ${({ textAlign }) => textAlign || 'left'};
  display: flex;
  flex-direction: column;
  justify-content: center;

  max-width: 55rem;
  margin: 0 auto;
  height: 100%;

  @media (max-width: 768px) {
    max-width: 100%;
    padding: 0 2rem;
    margin: 2rem auto;
  }
`

export const Title = styled.h2`
  font-size: 5rem;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.primary2};
  margin: 0;
  max-width: 55rem;

  @media (max-width: 768px) {
    font-size: 3.8rem;
  }
`

export const SmallCapsText = styled.p`
  font-size: 1.5rem;
  color: ${({ theme }) => theme.colors.white};
  margin: 0;
  margin-top: 1rem;
  margin-bottom: 2rem;
  text-transform: uppercase;
`

export const Logo = styled.img`
  position: absolute;
  bottom: 20%;
  right: 0%;
  /* transform: translate(50%, -50%); */
  width: 380px;

  @media (max-width: 768px) {
    width: 280px;
    transform: translateX(50%);
    left: 2.5rem;
    bottom: -50px;
  }
`

// export const ImageAd = ({ src, logo, title, description, left, bottom, right }) => {
//   return (
//     <Layout>
//       <Image src={src} alt='fast-truck-service overnight urgent delivery' />
//       <FloatingDiv left={left} bottom={bottom} right={right}>
//         <Title>{title}</Title>
//         <SmallCapsText>{description}</SmallCapsText>
//       </FloatingDiv>
//       {logo && <Logo src={logo} alt='logo' data-aos='fade-right' data-aos-duration='1200' />}
//     </Layout>
//   )
// }

export const ImageAd = ({ src, logo, title, description, layoutReversed }) => {
  return (
    <Layout>
      <GridRow col='2' height='100%' layoutReversed={layoutReversed} mobile>
        <Image src={src} alt='fast-truck-service overnight urgent delivery' />
        {/* <img src='' alt='' /> */}
        <ContentDiv>
          <Title>{title}</Title>
          <SmallCapsText>{description}</SmallCapsText>
          {logo && <Logo src={logo} alt='logo' data-aos='fade-right' data-aos-duration='1200' />}
        </ContentDiv>
      </GridRow>
    </Layout>
  )
}
