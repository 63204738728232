import styled from 'styled-components'
// import PageLayoutRef from '../../components/layout/PageLayout'}

export const PageLayout = styled.div`
  margin-top: 15vh;
`

export const Title = styled.h1`
  font-size: 5rem;
  color: ${({ theme }) => theme.colors.white};
  margin: 0;
  text-align: left;

  @media screen and (max-width: 768px) {
    font-size: 4rem;
  }
`

export const Subheading = styled.p`
  color: ${({ theme }) => theme.colors.white};
  font-size: 1.85rem;
  margin-bottom: 3rem;
  text-align: left;

  @media screen and (max-width: 768px) {
    font-size: 1.8rem;
  }
`

export const Layout = styled.div`
  z-index: 1;
  background: #f9f9f9; // Light background for a clean look
  background: url('/img/our-team.jpg') no-repeat center center;
  background-size: cover;
  min-height: 85vh;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 15vh;
  width: 100%;
  overflow: hidden;
  position: relative;

  .error-message {
    margin-bottom: 2rem;
    font-size: 1.4rem;
  }

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to right, rgba(36, 56, 89, 0.8) 0%, rgba(0, 0, 255, 0) 50%, transparent 100%);
    // Apply a blur effect to the left side by using a wider element and clipping it
    /* filter: blur(8px); */
    /* transform: translateX(-25%); */
    width: 125%;
    z-index: -1;
  }

  @media screen and (max-width: 768px) {
    margin-top: 14vh;
  }
`

export const Col = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 0 2rem;
  max-width: 700px;
  margin: 0;
  margin-left: 4vw;
`
