import styled from 'styled-components'
import { Hero } from './Hero'
import { GeneralDescription } from './GeneralDescription'
import { ServicesOverview } from './ServicesOverview'
import { CTASecondary } from './CTASecondary'
import { Helmet } from 'react-helmet'
import { SloganSection } from './SloganSection'
import { ImageAd1 } from './ImageAd/ImageAd1'
import { ImageAd2 } from './ImageAd/ImageAd2'
import { RangeOfServices } from './RangeOfServices'
import { useLocalizedPath } from '../../components/hooks/useLocalizedPath'
import { useHrefLangLinks } from '../../components/hooks/useHrefLangLinks'
import WebsiteMainTemplate from '../../components/layouts/WebsiteMainTemplate'

export const Homepage = () => {
  useLocalizedPath()
  const hrefLangLinks = useHrefLangLinks()

  return (
    <WebsiteMainTemplate>
      <Helmet>
        <title>Access Courtier inc. | Courtier en transport</title>
        <meta name='description' content='' />
        <meta name='keywords' content='' />
        {hrefLangLinks.map((link) => (
          <link key={link.href} rel='alternate' hrefLang={link.hrefLang} href={link.href} />
        ))}
      </Helmet>

      <Hero />
      <ServicesOverview />
      <SloganSection />
      <GeneralDescription />
      <ImageAd1 />
      <RangeOfServices />
      <ImageAd2 />
      <CTASecondary />
    </WebsiteMainTemplate>
  )
}

export default Homepage
