import styled from 'styled-components'

import { Button, Checkbox, Form, FormField, Alert } from '../../components2B/boilerplate-components'
import BaseIcon from '../../app/media/iconss/BaseIcon.js'

export const Layout = styled.div`
  background: purple;
  margin-top: 15vh;
  height: 100vh;
`

export const TestPage = () => {
  return (
    <Layout>
      {/* <BaseIcon /> */}
      {/* <Button primary>Primary</Button>
      <Form>
        <FormField>
          <Checkbox label='Test' />
        </FormField>
        <Button>Test</Button>
        <Alert type='success'>Test</Alert>
      </Form> */}
    </Layout>
  )
}
