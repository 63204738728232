import styled from 'styled-components'
import { Col as ColRef } from '../../components/layouts/Col'

export const Layout = styled.div`
  background-color: #f4f4f4;
  background-color: white;

  display: flex;
  flex-direction: column;
  align-items: center;
`

export const Hero = styled.section`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
  min-height: 65vh;
  margin-top: 15vh;
  width: 100%;
  position: relative;
  padding-left: 6rem;
  overflow: hidden;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: url('/img/hero-services.webp') no-repeat center center/cover;
    background-size: cover;
    //position top of picture:
    background-position: top;
    z-index: 0;
  }

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to right, rgba(36, 56, 89, 0.8) 0%, rgba(0, 0, 255, 0) 50%, transparent 100%);
    // Apply a blur effect to the left side by using a wider element and clipping it
    /* filter: blur(8px); */
    /* transform: translateX(-25%); */
    width: 125%;
  }

  @media screen and (max-width: 768px) {
    padding-left: 0;
    min-height: 100vh;
  }
`

// const HeroTitle = styled.h1`
//   font-size: 4rem;
//   margin: 0;
//   padding: 20px;
//   z-index: 1; // Ensuring the text is above both the image and overlay
//   position: relative;
//   color: white; // Adjust text color for better visibility against the overlay
// `

export const HeroTitle = styled.h1`
  font-size: 7rem;
  margin: 0 auto;
  margin-top: 2rem;
  color: #1b75bc;
  z-index: 1;
  text-align: left;
  width: 85%;

  @media screen and (max-width: 768px) {
    font-size: 3.4rem;
    width: 92%;
  }
`

export const Subheading = styled.h2`
  font-size: 4.5rem;
  margin: 0;
  margin-top: 2.5rem;
`

export const H1 = styled.h1`
  color: #333;
  text-align: center;
  margin-bottom: 20px;
`

export const H2 = styled.h2`
  font-size: 5rem;
  /* margin: 0;*/
  margin-top: 5rem;
  color: #1b75bc;
  text-align: center;

  @media screen and (max-width: 768px) {
    font-size: 4rem;
  }
`

export const Cards = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
  margin-top: 20px;
  margin-bottom: 5.5rem;
  margin: 4rem 0;

  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`

export const Card = styled.div`
  background: white;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
`

export const CardTitle = styled.h3`
  color: #1b75bc;
  margin-bottom: 10px;
  font-size: 1.85rem;
  font-weight: 600;
  letter-spacing: 0.15px;
`

export const CardDescription = styled.p`
  color: #666;
`

export const Col = styled(ColRef)`
  max-width: 47vw;
  /* 
  margin-top: 2rem;
  margin-bottom: 8rem; */
  z-index: 1;
  text-align: left;
  margin: 0 !important;
  margin-left: 6rem;

  @media screen and (max-width: 768px) {
    margin-left: 0;
    padding: 0 2rem;
    max-width: 100%;
  }
`

export const Slogan = styled.h1`
  font-size: 3.8rem;
  font-weight: 600;
  margin: 0;
  color: white;

  @media screen and (max-width: 768px) {
    font-size: 2.5rem;
  }
`

export const ServiceSection = styled.section`
  padding: 40px;
  margin-bottom: 20px;
  background-color: #f5f5f5;
  border-radius: 8px;

  @media screen and (max-width: 768px) {
    padding: 20px;
  }
`

export const SectionTitle = styled.h2`
  font-size: 24px;
  color: #333;
`

export const SectionContent = styled.div`
  font-size: 18px;
  margin-top: 10px;
`

export const P = styled.p`
  font-size: 1.8rem;
  font-weight: 500;
  color: white;
`

export const List = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`

export const ListItem = styled.li`
  margin-bottom: 2rem;
  font-size: 1.4rem;
  list-style: none;
  text-align: left;
  width: fit-content;
  margin: 1rem auto;

  @media screen and (max-width: 768px) {
    text-align: center;
  }
`

export const ListTitle = styled.h3`
  font-size: 2.5rem;
  font-weight: 600;
  color: ${(props) => props.theme.colors.primary};
`

export const Section = styled.section`
  margin: ${(props) => props.margin || '4rem 0'};
  text-align: center;

  /* @media screen and (max-width: 768px) {
    margin: 2rem 0;
  } */
`

export const Label = styled.h3`
  font-size: 1.45rem;
  font-weight: 600;
  background: ${(props) => props.theme.colors.primary};
  color: white;
  padding: 0.5rem 0.2rem;
  border-radius: 5px;
`

export const Item = styled.div`
  display: flex;
  align-items: center;
  margin: 1rem 0;
`

export const Icon = styled.img`
  width: 50px;
  height: 50px;
  margin-right: 1rem;
`

export const Services = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
  margin-top: 20px;
`

export const Service = styled.div`
  background: white;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
`

export const ServiceTitle = styled.h3`
  color: #1b75bc;
  margin-bottom: 10px;
  font-size: 1.85rem;
  font-weight: 600;
  letter-spacing: 0.15px;
`

export const ServiceDescription = styled.p`
  color: #666;
`
