import styled from 'styled-components'
import { NavLink as RouterNavLink } from 'react-router-dom'
import { Row as RowRef } from '../../../components/layouts/Row'

export const HeaderContainer = styled.div`
  position: fixed;
  width: 100%;
  display: flex;
  height: 15vh;
  flex-direction: column;
  visibility: ${({ isVisible }) => (isVisible ? 'visible' : 'hidden')};
  /* transition: visibility 0.3s ease-in-out, top 0.3s ease-in-out; */
  transition: visibility 0.3s ease-in-out;
  z-index: 100;
`

export const TopHeader = styled.div`
  background-color: #314263;
  height: 10vh;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;

  @media (max-width: 768px) {
    height: 15vh;
  }
`

export const BottomHeader = styled.div`
  background-color: #f4f4f4;
  height: 5vh;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 4rem;
  font-size: 1.35rem;
  box-shadow: 0 2px 2px rgba(135, 135, 135, 0.2);

  @media (max-width: 768px) {
    display: none;
  }
`

export const Hamburger = styled.div`
  cursor: pointer;
  display: none; // Initially hidden on desktop

  @media (max-width: 768px) {
    display: block; // Show only on mobile
  }

  // Add hamburger icon styles here
  div {
    width: 30px;
    height: 3px;
    background-color: white;
    margin: 6px 0;
    transition: 0.4s;
  }
`

export const Menu = styled.div`
  display: ${({ open }) => (open ? 'flex' : 'none')};
  display: none;
  position: absolute;
  position: relative;
  top: 15vh; // Adjust based on the total height of the two headers
  left: 0;
  width: 100%;
  background-color: #fafafa;
  padding: 2rem;
  z-index: 1000;

  @media (max-width: 768px) {
    position: fixed; // Make the menu fixed to cover the whole screen
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    /* left: ${({ open }) => (open ? '0' : '-100vw')}; // Slide in from the left */
    transition: left 0.3s ease-in-out;
    display: flex;
    flex-direction: column; // Stack links vertically
    justify-content: center;
    align-items: center;
    display: flex; // Always use flex, but hide offscreen if not open
    transform: ${({ open }) => (open ? 'translateX(0)' : 'translateX(-100%)')};
    z-index: 1000;
    font-size: 2.3rem;
  }
`

export const CloseIcon = styled.div`
  cursor: pointer;

  @media (max-width: 768px) {
    position: absolute;
    top: 2rem;
    right: 2rem;
  }
`

export const List = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 100%;

  @media (max-width: 768px) {
    flex-direction: column;
    > li {
      margin: 1.2rem 0;
    }
  }
`

export const NavLink = styled(RouterNavLink)`
  text-decoration: none;
  color: black;
  padding: 0 3rem;
  // Add more styles as needed
`

export const Logo = styled.img`
  cursor: pointer;
  width: ${({ width }) => width || '300px;'};
  height: auto;

  @media (max-width: 768px) {
    max-width: 295px;
    width: 100%;
    margin: 0;
    padding: 2rem;
  }
`

export const CTAButton = styled.button`
  background: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.primary};
  padding: 1rem 2rem;
  margin-right: 2rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);

  &:hover {
    background: white;
    color: ${({ theme }) => theme.colors.primary};
  }
`

export const Row = styled(RowRef)`
  /* justify-content: flex-end; */
  width: fit-content;
  margin: 0;
  margin-left: auto;
`

export const RowHideIfMobile = styled(RowRef)`
  /* justify-content: flex-end; */
  width: fit-content;
  margin: 0;
  margin-left: auto;

  @media (max-width: 768px) {
    display: none;
  }
`
