import styled from 'styled-components'

export const Layout = styled.div`
  width: 100%;
  overflow: hidden;
`

export const Services = styled.section`
  width: 100%;
`

export const ServiceCard = styled.div`
  max-width: 750px;
  width: 100%;
  min-height: 325px;
  border-radius: 10px;
  margin: 25vh auto;

  &:first-child {
    margin-top: 10vh;
  }

  &:nth-child(odd) {
    margin-left: 8rem;

    > h2,
    p {
      margin-right: auto;
    }
  }

  &:nth-child(even) {
    margin-right: 8rem;

    > h2,
    p {
      margin-left: auto;
      text-align: right;
    }
  }

  @media screen and (max-width: 768px) {
    width: 90%;
    margin: 16vh auto;

    &:nth-child(odd) {
      margin-left: 1rem;
    }

    &:nth-child(even) {
      margin-right: 1rem;
    }
  }
`

export const ServiceTitle = styled.h2`
  font-size: 1.9rem;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.primary};
  text-transform: uppercase;
  letter-spacing: 0.15px;
  width: fit-content;
`
export const ServiceDescription = styled.p`
  font-size: 1.65rem;
  color: ${({ theme }) => theme.colors.text};
  margin-top: 1rem;
  width: 90%;
  line-height: 1.5;
  text-align: left;
`

export const Img = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 12px;
`

export const H2 = styled.h2`
  color: #1b75bc;
  font-size: 5rem;
  text-align: left;
  padding: 20px;

  @media screen and (max-width: 768px) {
    font-size: 4.2rem;
    text-align: center;
  }
`
