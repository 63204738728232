import styled from 'styled-components'
import ButtonRef from '../../../components/elements/Button'
import { Link } from 'react-router-dom'

export const Layout = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin: 12vh 0;
`

export const SectionTitle = styled.h2`
  font-size: 2rem;
  text-align: center;
  margin-bottom: 2rem;
`

export const ServicesGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 20px;
  width: 100%;
  padding: ${({ padding }) => padding || '0 2rem'};

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`

// Styled component for individual service cards
export const ServiceCard = styled(Link)`
  position: relative;
  width: 100%;
  height: 300px;
  overflow: hidden;
  border-radius: 10px;
  background: #ccc; /* Fallback for browsers that do not support gradients */
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${(props) => props.bgImage}) center/cover no-repeat;
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  transition: background 0.3s ease-in-out;

  // Style for the icon and text wrapper to ensure they're always centered
  .content {
    text-align: left;
    z-index: 10;
    color: white;
    font-size: 2.35rem;
    font-weight: bold;
    width: 100%;
    padding: 1rem;
    margin-left: 2rem;
    max-width: 380px;
    /* background: rgba(0, 0, 0, 0.5); */

    svg {
      width: 100%;
      max-width: 70px;
      height: auto;

      path {
        fill: white;
      }
    }
  }

  // Initial state of the background image (hidden)
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: inherit;
    filter: blur(5px);
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
  }

  // Hover state to reveal the background image
  &:hover:before {
    opacity: 1;
  }
`

export const Button = styled(ButtonRef)`
  // animate-fill the button on hover (from left to right nice animation)
  background: ${({ theme }) => theme.colors.primary};

  transition: all 0.5s ease-out;
  padding: 1.4rem 4rem;
  border-radius: 0.75rem;

  &:hover {
    background-position: 0%;
    color: #fff;
  }
`
