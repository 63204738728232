import React from 'react'
import styled from 'styled-components'
import { NavLink as NavLinkRef, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { BasicLinks } from './templates/BasicLinks'
import { ContactInfo } from './templates/ContactInfo'
import { SocialFollow } from './templates/SocialFollow'
import { AboutUs } from './templates/AboutUs'
// import { ReactComponent as LogoMark } from '../../media/img/logoMark.svg' // Adjust path as necessary

const FooterContainer = styled.footer`
  background-color: #f7f8f9;
  color: ${({ theme }) => theme.colors.primary};
  padding: 4rem; // Adjusted for all screen sizes
  padding-bottom: 2rem;
  display: flex;
  justify-content: space-around; // Evenly space elements
  align-items: flex-start;
  flex-wrap: wrap;

  @media (max-width: 768px) {
    flex-direction: column; // Stack elements vertically on smaller screens
    align-items: center; // Center align on smaller screens
  }
`

const Content = styled.div`
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: space-around; // Evenly space elements
  align-items: flex-start;
  flex-wrap: wrap;

  @media (max-width: 768px) {
    flex-direction: column; // Stack elements vertically on smaller screens
    align-items: center; // Center align on smaller screens
  }
`

const FooterSection = styled.div`
  margin: 1rem;
  flex: 1;
  min-width: 200px; // Ensures a minimum width for each section

  @media (max-width: 768px) {
    flex-basis: 100%; // Full width on smaller screens
    margin: 1rem 0;
    text-align: center; // Center align text on smaller screens
  }
`

const FooterTitle = styled.h4`
  margin-bottom: 1rem;
  margin-top: 0;
  color: grey;
  letter-spacing: 0.5px;
`

const NavLink = styled(NavLinkRef)`
  color: white;
  text-decoration: none;
  margin-bottom: 0.5rem;
  display: block;

  &:hover {
    color: #1b75bc;
  }
`

const LogoContainer = styled.div`
  margin-bottom: 2rem;
  margin-right: 4rem;

  @media (max-width: 768px) {
    margin-bottom: 1rem;
    margin-right: 0;
  }

  & svg {
    width: 80px; // Slightly smaller logo for mobile
    height: 80px;
    @media (min-width: 768px) {
      width: 100px; // Original size for larger screens
      height: 100px;
    }
  }
`

export const Footer = () => {
  const { t } = useTranslation('footer')
  const location = useNavigate()

  // Example condition for template selection (could be based on route, user settings, etc.)
  const isTemplateOne = location.pathname === '/' // Adjust your condition as needed
  const templateCondition = 'BasicLinks' // This should be dynamic based on your app's logic

  const renderTemplate = () => {
    switch (templateCondition) {
      case 'BasicLinks':
        return <BasicLinks />
      case 'ContactInfo':
        return <ContactInfo />
      case 'SocialFollow':
        return <SocialFollow />
      case 'AboutUs':
        return <AboutUs />
      default:
        return <BasicLinks /> // Fallback template
    }
  }

  return <FooterContainer>{renderTemplate()}</FooterContainer>
}

export default Footer
