import styled from 'styled-components'
import { Row } from '../../../components/layouts/Row'
import { useTranslation } from 'react-i18next'
import { Col } from '../../../components/layouts/Col'

export const DescriptionCenter = styled.p`
  text-align: center;
  font-size: 2.85rem;

  @media (max-width: 768px) {
    width: 100%;
    font-size: 2.15rem;
    text-align: left;
  }
`

export const Layout = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 0 10rem;

  background: #f8f8f8;
  margin: 0 auto;
  margin-top: 10vh;
  margin-bottom: 10vh;
  width: 95%;
  min-height: 50vh;
  border-radius: 10px;
  padding: 0 2.5rem;

  @media screen and (max-width: 768px) {
    margin-top: 5vh;
    margin-bottom: 5vh;
    font-size: 2.3rem;
  }
`

export const ColorEmphasis = styled.span`
  color: ${({ theme }) => theme.colors.primary2};
`

export const GeneralDescription = () => {
  const { t } = useTranslation('home')

  const description = (
    <>
      {t('generalDescription.part1')}
      &nbsp;<ColorEmphasis>{t('generalDescription.part2')}</ColorEmphasis>
      <br />
      {t('generalDescription.part3')}
    </>
  )

  return (
    <Layout>
      <Col justify='center' align='center'>
        <DescriptionCenter>{description}</DescriptionCenter>
        {/* TODO    <i>Demande de soumission</i> // en bleu, un peu plus gros */}
      </Col>
    </Layout>
  )
}
