import React from 'react'
import styled from 'styled-components'

const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
`

const Input = styled.input`
  margin-right: 10px;
`

const Checkbox = ({ label }) => (
  <CheckboxContainer>
    <Input type='checkbox' />
    {label}
  </CheckboxContainer>
)

export { Checkbox }
