import { css, createGlobalStyle } from 'styled-components'

const fontFaces = css`
  /* @font-face {
    font-family: 'Spartan MB';
    src: url('SpartanMB-Light.otf') format('otf');
  }
   */
`

const GlobalStyle = createGlobalStyle`

/* primary font */
${fontFaces}


@import url('https://fonts.googleapis.com/css2?family=Roboto+Flex:opsz,wght@8..144,100..1000&family=Work+Sans:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

*{
  box-sizing:border-box;
  -webkit-box-sizing: border-box;
}

#root{
  position:relative;
}

  html,
  body{
    scroll-behavior: smooth;
    background-color: ${({ theme }) => theme.colors.body};
    height: 100%;
    width: 100%;
    font-size: 62.5%;
    /* line-height: 1.5; */
    margin: 0;
    padding: 0;
    font-family: inherit;
    -webkit-font-smoothing: antialiased;
    

    /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;  /* Safari and Chrome */
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  }


  h1,h2,h3,h4,h5,h6{
    font-family: ${({ theme }) => theme.fontFamily};
    /* color: ${({ theme }) => theme.colors.text}; */
    /* letter-spacing:-0.95px; */
    letter-spacing:-0px;
    font-weight:100;
  }
  

  body {
    font-family:'Instrument Sans', 'Inter', Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    font-size: 1.6em;
  }


/* // style resets */

  input{
    font-family:inherit;
  }

  button{
    border:0;
    background:transparent;
  }

  a{
    text-decoration:none;
    color: unset;
    margin:0;
  }

  // remove blue border focus
  input:focus,
  select:focus,
  textarea:focus,
  button:focus {
      outline: none;
  }



  // general basic animations

  @keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.fadeIn {
  animation: fadeIn 0.5s ease forwards;
}

.fadeOut {
  animation: fadeOut 0.5s ease forwards;
}


// react transition group animations:

.fade-enter {
  opacity: 0;
}
.fade-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in;
}
.fade-exit {
  opacity: 1;
}
.fade-exit-active {
  opacity: 0;
  transition: opacity 500ms ease-out;
}
 
`

export default GlobalStyle
