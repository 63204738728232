import styled from 'styled-components'

// export const H2 = styled.h2`
//   font-size: 2.3rem;
//   font-weight: 700;
//   margin: 1.4rem 0;
//   /* margin-left: 5rem; */
// `

export const H2 = styled.h2`
  text-align: ${({ left }) => (left ? 'left' : 'center')};
  margin-top: ${({ top }) => (top ? top : '10rem')};
  font-size: ${({ size }) => (size ? size : '2.3rem')};
  font-weight: 500;
  color: ${({ theme }) => theme.colors.primary};
  letter-spacing: 0.5px;
  margin-bottom: ${({ bottom }) => (bottom ? bottom : '6rem')};
  text-transform: uppercase;
`
