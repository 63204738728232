import styled from 'styled-components/macro'
import { Link } from 'react-router-dom'
import { CTAButton as CTAButtonRef } from '../header/Style'

let footerHeight = 6

export const StyledFooter = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* position: fixed;
  top: 0rem;
  right: 0rem; */
  width: 100%;
  height: ${footerHeight}rem;
  background: #72a9b0;
  z-index: 1;

  &::before {
    /* content: '';
    position: absolute;
    top: 0rem;
    left: 0rem;
    width: 0; */
  }
`

export const NavLinks = styled.ul`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-right: 8rem;

  @media screen and (max-width: 768px) {
    display: none;
  }
`

export const NavLink = styled(Link)`
  display: flex;
  list-style: none;
  margin-left: 2rem;
  font-size: 1.6rem;
  color: #333;
  font-weight: bold;

  path,
  span {
    fill: #053b47;
    color: #053b47;
  }

  &:hover {
    color: #666;
  }

  @media screen and (max-width: 768px) {
    display: block;
    margin-left: 0px;
  }
`

export const CTAButton = styled(CTAButtonRef)`
  width: 100%;
  max-width: 26rem;

  @media screen and (max-width: 768px) {
    max-width: 100%;
    font-size: 1.65rem;
    margin-right: 0 !important;
  }
`
