import styled, { css } from 'styled-components'

const StyledButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${(props) => props.width || 'fit-content'};
  background: ${({ bgColor }) => bgColor || 'transparent'};
  border: 2px solid white;
  color: ${(props) => props.theme.colors.body || 'white'};
  border-radius: 1rem;
  padding: 1.4rem 2rem;
  cursor: pointer;
  font-size: 1.8rem;
  font-weight: 500;

  margin: ${({ margin }) => margin};
  margin-left: ${({ marginLeft }) => marginLeft};
  margin-right: ${({ marginRight }) => marginRight};
  margin-top: ${({ marginTop }) => marginTop || '3.5rem'};

  ${(props) =>
    props.borderColor &&
    css`
      border: 1px solid ${(props) => props.borderColor};
    `}

  svg,
  i {
    margin-left: 0.4rem;
  }

  &:hover {
    svg.circle-left {
      animation: circleLeft 0.5s forwards;
    }

    svg.circle-right {
      animation: circleRight 0.5s forwards;
    }
  }

  @keyframes circleLeft {
    0% {
      transform: translateX(0);
      opacity: 1;
    }
    50% {
      transform: translateX(-100%);
      opacity: 0;
    }
    100% {
      transform: translateX(-100%);
      opacity: 0;
    }
  }

  @keyframes circleRight {
    0% {
      transform: translateX(100%);
      opacity: 0;
    }
    50% {
      transform: translateX(100%);
      opacity: 0;
    }
    100% {
      transform: translateX(0);
      opacity: 1;
    }
  }

  &:active {
    color: ${(props) => props.theme.colors.secondary};
  }
`

export default StyledButton
