import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import styled from 'styled-components'

const SwitcherContainer = styled.div`
  position: relative;
  cursor: pointer;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  padding: 10px;
  font-size: 1.2rem;
  color: ${(props) => props.textColor};
`

const Dropdown = styled.div`
  position: absolute;
  top: 100%;
  right: 0;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 5px 0;
  width: fit-content;
  visibility: ${(props) => (props.isOpen ? 'visible' : 'hidden')};
  opacity: ${(props) => (props.isOpen ? '1' : '0')};
  transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
  z-index: 10; // Ensure dropdown is above other content
`

const LanguageOption = styled.div`
  cursor: pointer;
  color: #333;
  padding: 10px;
  margin-left: auto;
  width: 100%;
  text-align: center;
  &:hover {
    background-color: #f0f0f0;
  }
`

const LanguageSwitcher = ({ textColor }) => {
  const [isOpen, setIsOpen] = useState(false)
  const { i18n } = useTranslation()
  const location = useLocation()
  const navigate = useNavigate()

  const languages = [
    { code: 'en', name: 'ENGLISH' },
    { code: 'fr', name: 'FRANÇAIS' },
    // Add more languages as needed
  ]

  const supportedLanguages = ['en', 'fr']

  // Function to get a readable name for the current language
  const getCurrentLanguageName = () => {
    const currentLangCode = i18n.language.split('-')[0]
    const currentLanguage = languages.find((lang) => lang.code !== currentLangCode)
    return currentLanguage ? currentLanguage.name : ''
  }

  // const switchLanguage = (lng) => {
  //   i18n.changeLanguage(lng)
  //   setIsOpen(false) // Close the dropdown after switching
  // }

  const switchLanguage = (lng) => {
    i18n.changeLanguage(lng).then(() => {
      const pathSegments = location.pathname.split('/').filter(Boolean)

      // Remove the current language code if it's present in the path
      if (supportedLanguages.includes(pathSegments[0])) {
        pathSegments.shift()
      }

      // Reconstruct the path with the new language code
      const newPath = `/${lng}/${pathSegments.join('/')}`

      // Navigate to the new path
      navigate(newPath, { replace: true })
    })
  }

  // Determine the current language
  const currentLangCode = i18n.language.split('-')[0]
  // Sort languages so that the non-current language comes first
  const sortedLanguages = languages.filter((a) => a.code !== currentLangCode)

  return (
    <SwitcherContainer onClick={() => setIsOpen(!isOpen)} textColor={textColor}>
      {getCurrentLanguageName()}
      {isOpen && (
        <Dropdown isOpen={isOpen}>
          {sortedLanguages
            // .filter((lang) => lang.code !== i18n.language.split('-')[0]) // Filter out the current language
            .map((lang) => (
              <LanguageOption key={lang.code} onClick={() => switchLanguage(lang.code)}>
                {lang.name}
              </LanguageOption>
            ))}
        </Dropdown>
      )}
    </SwitcherContainer>
  )
}

export default LanguageSwitcher
