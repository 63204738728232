import React, { useState } from 'react'
import axios from 'axios'
import { useTranslation } from 'react-i18next'
import WebsiteMainTemplate from '../../components/layouts/WebsiteMainTemplate'
import { Layout, Col, Title, Subheading, Form, Label, Button } from './Style'

export const Contact = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
  })

  const [responseMessage, setResponseMessage] = useState('')
  const { t } = useTranslation('contact')

  const handleChange = (e) => {
    const { name, value } = e.target
    setFormData((prevState) => ({ ...prevState, [name]: value }))
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    try {
      const proxyURL = process.env.NODE_ENV === 'production' ? process.env.REACT_APP_API_URL : 'http://localhost:5000'
      const response = await axios.post(`${proxyURL}/api/contact`, formData)

      if (response.data.message === 'Thank you for contacting us.') {
        setResponseMessage(t('contact.thankYouResponse'))
      } else {
        setResponseMessage(response.data.message)
      }
    } catch (error) {
      setResponseMessage(t('contact.errorResponse'))
    }
  }

  // useEffect(() => {
  //   sendPageView()
  // }, [])

  return (
    <WebsiteMainTemplate>
      <Layout>
        <Col>
          {!responseMessage ? <Title>{t('contact.title')}</Title> : <Title>{t('contact.successTitle')}</Title>}
          {!responseMessage ? (
            <Subheading>{t('contact.subheading')}</Subheading>
          ) : (
            <center>
              <Subheading>{responseMessage}</Subheading>
            </center>
          )}
          {!responseMessage && (
            <Form onSubmit={handleSubmit}>
              <Label>
                {t('contact.name')}:
                <input type='text' name='name' value={formData.name} onChange={handleChange} required />
              </Label>
              <Label>
                {t('contact.email')}:
                <input type='email' name='email' value={formData.email} onChange={handleChange} required />
              </Label>
              <Label>
                {t('contact.message')}:
                <textarea name='message' value={formData.message} onChange={handleChange} required />
              </Label>
              <Button type='submit'>{t('contact.sendButton')}</Button>
            </Form>
          )}
        </Col>
      </Layout>
    </WebsiteMainTemplate>
  )
}

export default Contact
