import styled from 'styled-components/macro'
import { Subheading as SubheadingRef, H1 as H1Ref } from '../Hero/Style.js'
import { Col as ColRef } from '../../../components/layouts/Col'

export const Layout = styled.div`
  width: 100%;
  min-height: 100vh;
  /* background: url() no-repeat center center;
  background-size: cover;
  background-color: #f8f9fa;
  background-blend-mode: multiply;
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), url('/bg-gimq.jpg'); */
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0;
  margin: 7rem 0;

  button:nth-of-type(2) {
    background: red;
  }
`

export const Card = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  text-align: center;
  padding: 20px;
  background-color: #f8f9fa;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin: 20px;
  width: 90%;
  min-height: 55vh;
  margin: 1rem auto;
  opacity: 0.9;

  @media (max-width: 768px) {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
`

// Styled component for main title
export const H2 = styled.h2`
  color: #333;
  margin-bottom: 15px;
`

// Styled component for subtitle
export const SubH = styled.h3`
  color: #555;
  margin-bottom: 20px;
`

// Styled component for customer pain points
export const PainPoints = styled.div`
  color: #007bff;
  font-weight: bold;
  margin-bottom: 2.5rem;
  text-align: left;
  font-size: 1.65rem;
  line-height: 2;

  @media (max-width: 768px) {
    text-align: left;
    font-size: 1.6rem;
  }
`

// export const Col = styled(ColRef)`
//   padding: 0 2rem;
//   width: 100%;
//   height: 100%;

//   > * {
//     color: #1b75bc;
//   }
// `

export const H1 = styled(H1Ref)`
  font-size: 6rem;
  margin-top: 0;

  @media (max-width: 768px) {
    font-size: 4rem;
  }
`

export const Subheading = styled(SubheadingRef)`
  font-size: 2.1rem;

  @media (max-width: 768px) {
    font-size: 2.4rem;
  }
`

/// IN BP

export const CenteredButton = styled.button`
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 0.5rem 1rem;
  font-size: 1.5rem;
  cursor: pointer;
  transition: 0.3s;
  margin: 0 auto;
  display: block;
  text-align: center;
  text-decoration: none;

  &:hover {
    background-color: #0056b3;
  }
`

export const PreTitle = styled.span`
  font-size: 1.7rem;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.primary};
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 2rem;
`

export const Title = styled.h2`
  font-size: 5rem;
  font-weight: 600;
  width: 80%;
  text-align: center;

  @media (max-width: 768px) {
    font-size: 2.5rem;
  }
`

export const Col = styled(ColRef)`
  width: 80%;
  max-width: 40vw;
  margin: 0 auto;

  height: 50vh;
  padding: 0 0.15rem;

  &:nth-child(1) {
    margin-right: 0;
  }

  &:nth-child(2) {
    margin-left: 0;
    > div {
      background: ${({ theme }) => theme.colors.primary};
      color: white !important;
    }
  }

  @media (max-width: 768px) {
    width: 100%;
    max-width: 97%;
    margin: 0 auto;
    height: 100%;

    &:nth-child(1) {
      margin: 0 auto;
    }

    &:nth-child(2) {
      margin: 0 auto;
      margin-top: 5rem;
    }
  }
`

export const Block = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  padding: 1rem 2rem;
  background: ${({ theme }) => theme.colors.lightGrey};
  color: black;
  width: 100%;
  height: 100%;
  /* max-width: 40vw; */
  margin: 0 auto;
  text-align: center;
`

export const BlockTitle = styled.h3`
  font-weight: 600;
  font-size: 3.2rem;
  margin-top: 1rem;

  .small {
    font-size: 2rem;
    font-weight: 400;
  }
  .italic {
    font-style: italic;
  }

  &:nth-of-type(2) {
    color: white !important;
    background: purple;
  }
`

export const Text = styled.p`
  font-size: 2.1rem;
`

export const Button = styled.button`
  background: white;
  width: 100%;
  /* padding: 1rem 2rem; */
  font-size: 1.85rem;
  font-weight: bold;
  height: 7.5rem;
  color: ${({ theme }) => theme.colors.primary};
  background: ${({ theme }) => theme.colors.lightGrey};
  border-top: 10px solid ${({ theme }) => theme.colors.white};
  cursor: pointer;
  transition: 0.3s;

  &:hover {
    background: ${({ theme }) => theme.colors.primary};
    color: white;
    transition: 0.3s;
  }

  ${({ colored }) =>
    colored &&
    `
background: #314263 !important;
color:white;


&:hover {
    color: white;
    transition: 0.3s;
  }
  `}/* 
  &:nth-child(1) {
    &:hover {
      background: white;
      color: ${({ theme }) => theme.colors.primary};
      transition: 0.3s;
    }
  } */

  /* &:nth-of-type() {
    background: ${({ theme }) => theme.colors.primary};
    color: white;
  } */
`

export const OuterText = styled.p`
  font-size: 2.4rem;
  width: 60%;
  text-align: center;

  @media (max-width: 768px) {
    width: 85%;
    margin-top: 5rem;
  }
`
