import styled, { keyframes } from 'styled-components'
import { Col as ColRef } from '../../components/layouts/Col'
// import { Title as TitleRef, Subheading as SubheadingRef } from '../../components/elements/Title'

// export const Title = styled.h1``

// const fadeIn =
//   from {
//     opacity: 0;
//   }
//   to {
//     opacity: 1;
//   }
// `

// export const Container = styled.div`
//   padding: 2rem;
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   justify-content: center;
//   color: ${({ theme }) => theme.colors.text};
//   animation: ${fadeIn} 0.5s;
//   max-width: 50rem;
//   margin-left: auto;
//   margin-right: 4vw;

//   @media (max-width: 768px) {
//     margin: auto;
//   }
// `

// export const Title = styled.h1`
//   font-size: 3rem;
//   margin-bottom: 0.5rem;
//   text-align: center;
// `

export const Description = styled.p`
  font-size: 1.4rem;
  text-align: center;
  margin-bottom: 2rem;
`

// export const Form = styled.form`
//   max-width: 500px;
//   width: 100%;
//   display: flex;
//   flex-direction: column;
//   gap: 1.5rem;
// `

// export const Label = styled.label`
//   display: flex;
//   flex-direction: column;
//   font-size: 1.3rem;
//   font-weight: bold;
// `

export const Input = styled.input`
  padding: 0.5rem;
  margin-top: 0.5rem;
  border: 2px solid ${({ theme }) => theme.colors.text};
  border-radius: 5px;
  outline: none;
  transition: border-color 0.3s, transform 0.2s;

  &:focus,
  &:hover {
    border-color: ${({ theme }) => theme.colors.success};
    transform: scale(1.02);
  }
`

export const Textarea = styled.textarea`
  padding: 0.5rem;
  margin-top: 0.5rem;
  border: 2px solid ${({ theme }) => theme.colors.text};
  border-radius: 5px;
  outline: none;
  transition: border-color 0.3s, transform 0.2s;
  resize: vertical;

  &:focus,
  &:hover {
    border-color: ${({ theme }) => theme.colors.success};
    transform: scale(1.02);
  }
`

// export const Button = styled.button`
//   padding: 0.7rem 1.5rem;
//   background-color: ${({ theme }) => theme.colors.body2};
//   color: ${({ theme }) => theme.colors.text};
//   border: none;
//   border-radius: 5px;
//   cursor: pointer;
//   transition: background-color 0.3s, transform 0.2s;
//   font-weight: 600;

//   &:hover {
//     background-color: ${({ theme }) => theme.colors.success};
//     transform: translateY(-3px); // subtle "lift" effect
//   }
// `

export const Layout = styled.div`
  z-index: 1;
  background: #f9f9f9; // Light background for a clean look
  background: url('/img/contact-us-transport-broker.webp') no-repeat center center;
  background-size: cover;
  background-position: top;
  min-height: 85vh;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 15vh;
  width: 100%;
  overflow: hidden;
  position: relative;

  .error-message {
    margin-bottom: 2rem;
    font-size: 1.4rem;
  }

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to right, rgba(36, 56, 89, 0.8) 0%, rgba(0, 0, 255, 0) 50%, transparent 100%);
    // Apply a blur effect to the left side by using a wider element and clipping it
    /* filter: blur(8px); */
    /* transform: translateX(-25%); */
    width: 125%;
  }

  @media screen and (max-width: 768px) {
    margin-top: 14vh;
    background: url('/img/contact-us-transport-broker.webp') no-repeat center center fixed;
    background-position: top;
    background-size: cover;
  }
`

// export const Title = styled(TitleRef)`
export const Title = styled.div`
  font-size: 5rem;
  color: ${({ theme }) => theme.colors.white};
  margin: 0;
  text-align: left;

  @media screen and (max-width: 768px) {
    font-size: 4rem;
  }
`

/* export const Subheading = styled(SubheadingRef)` */
export const Subheading = styled.div`
  color: ${({ theme }) => theme.colors.white};
  font-size: 1.85rem;
  margin-bottom: 3rem;
  text-align: left;
  line-height: 1.5;

  @media screen and (max-width: 768px) {
    font-size: 1.8rem;
  }
`

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 500px; // Set a max width for better readability
  margin: auto;

  input,
  textarea {
    font-size: 1.6rem;
    padding: 1.2rem;
    border: 2px solid #ddd;
    border-radius: 8px;
    margin-top: 0.25rem;
    margin-bottom: 1rem;
    width: 100%;
    transition: border-color 0.3s, box-shadow 0.3s;

    &:focus {
      border-color: #1b75bc;
      box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    }
  }

  textarea {
    height: 150px; // Larger area for messages
  }
`

export const Label = styled.label`
  font-size: 1.6rem;
  color: ${({ theme }) => theme.colors.white};
  display: block;
  width: 100%;
`

export const ButtonRef = styled.button`
  max-width: 30rem;
  border-radius: 2.5rem;
  border: none;
  background-color: #fff;
  color: #1b75bc;
  margin-top: 4rem;
  padding: 1rem 2rem;
  font-size: 1.55rem;
  font-weight: 600;
  box-shadow: 0 0 1rem rgba(0, 0, 0, 0.1);
  cursor: pointer;
  text-align: center;
  text-decoration: none;

  // animate-fill the button on hover (from left to right nice animation)
  background-image: linear-gradient(to right, #1b75bc 50%, transparent 50%);
  background-position: 100%;
  background-size: 200% 100%;

  transition: all 0.5s ease-out;

  &:hover {
    background-position: 0%;
    color: #fff;
  }

  @media screen and (max-width: 768px) {
    font-size: 1.65rem;
  }
`

// Updated Button styling
export const Button = styled(ButtonRef)`
  margin-top: 1rem;
  width: 100%;
  max-width: 260px; // More appropriate width
  display: block;
  margin-left: auto;
  margin-right: auto;
  background-color: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.primary};
  border: none;
  padding: 1rem 2rem;
  font-size: 1.4rem;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s;

  &:hover {
    background: ${({ theme }) => theme.colors.primary};
    transform: translateY(-2px); // Slight lift effect
  }
`

// export const Container = styled(ContainerRef)`
export const Container = styled.div`
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  max-width: unset;
  width: 100%;
  margin: auto;
  margin-top: 10vh;
  background: white;
  padding-bottom: 10rem;
`

export const Col = styled(ColRef)`
  width: 100%;
  /* max-width: 60rem; */
  margin-right: auto;
  margin-left: 4vw;
  max-width: 500px;
  z-index: 1;

  @media screen and (max-width: 768px) {
    padding: 0 2rem;
    margin-top: 15vh;
  }
`
