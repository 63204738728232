import styled from 'styled-components'
import { Image as ImageRef } from '../../../components/elements/Image'

export const Layout = styled.div`
  width: 100%;
`

export const Text = styled.span`
  font-size: 4rem;
  color: ${({ theme }) => theme.colors.primary};
  width: 100%;
  margin-left: 15rem;

  @media (max-width: 768px) {
    font-size: 2.35rem;
    margin-left: 0;
    margin-right: 0;
    margin: 0 auto;
    width: fit-content;
  }
`

export const Slogan = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 2rem;

  @media (max-width: 768px) {
    width: 100%;
  }
`

export const Image = styled(ImageRef)`
  width: 100%;

  @media (max-width: 768px) {
    margin-top: 4rem;
  }
`

export const SloganText = styled.h2`
  font-size: 3rem;
  font-weight: 300;
  text-align: center;
  margin: 0;
  color: ${({ theme }) => theme.colors.primary};

  @media (max-width: 768px) {
    font-size: 2rem;
  }
`
