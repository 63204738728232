import { useLocation } from 'react-router-dom'

const supportedLanguages = ['en', 'fr'] // Extend this list as needed

export const useHrefLangLinks = (baseUrl = process.env.REACT_APP_BASE_URL) => {
  const location = useLocation()
  const pathWithoutLang = location.pathname
    .split('/')
    .filter((part) => !supportedLanguages.includes(part))
    .join('/')

  // Create hreflang links
  const hrefLangLinks = supportedLanguages.map((lang) => ({
    rel: 'alternate',
    hreflang: lang,
    href: `${baseUrl}/${lang}${pathWithoutLang === '' ? '' : `/${pathWithoutLang}`}`,
  }))

  return hrefLangLinks
}
