import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

const Section = styled.div`
  padding: 20px;
`

const Title = styled.h4`
  margin-bottom: 1rem;
  color: #ccc;
`

const Paragraph = styled.p`
  margin-bottom: 0.5rem;
  display: flex;
  align-items: center;
`

const Icon = styled.i`
  margin-right: 10px;
`

export const ContactInfo = () => {
  const { t } = useTranslation('common')

  return (
    <Section>
      <Title>{t('footer.contactInfo.title')}</Title>
      <Paragraph>
        <Icon className='fas fa-map-marker-alt'></Icon>
        {t('footer.contactInfo.address')}
      </Paragraph>
      <Paragraph>
        <Icon className='fas fa-phone-alt'></Icon>
        {t('footer.contactInfo.phone')}
      </Paragraph>
      <Paragraph>
        <Icon className='fas fa-envelope'></Icon>
        {t('footer.contactInfo.email')}
      </Paragraph>
    </Section>
  )
}
