import React, { Suspense, useState } from 'react'
import { Routes, Route, Navigate } from 'react-router-dom'
import styled from 'styled-components'

import GlobalStyle from './theme/global-styles'
import { Header } from './theme/header/index'
import { ScrollToTop } from '../utils/routing/ScrollToTop'

// import { PrivateRoute } from '../utils/api/routing/PrivateRoute'

import useAuthStore from '../features/auth/authStore'

import Footer from './theme/footer'

import { routeConfig } from './routeConfig'

const AppContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 100%;
`

const App = () => {
  const [loading, setLoading] = useState(false)
  const [animationClass, setAnimationClass] = useState('')

  const [menuOpened, setMenuOpened] = useState(false)

  const { checkAuthentication, isAuthenticated, loaded, setNoAuth, setUserMode } = useAuthStore()

  // useEffect(() => {
  //   const storedToken = localStorage.getItem('token')
  //   const minimumDisplayTime = Math.random() * (2.7 - 2.1) + 2.1
  //   const displayTimePromise = new Promise((resolve) => setTimeout(resolve, minimumDisplayTime * 1000))

  //   const loadContentAsync = async () => {
  //     if (storedToken) {
  //       setAuthToken(storedToken)
  //       await checkAuthentication()
  //     }
  //     // Add other asynchronous loading logic here if necessary
  //   }

  //   Promise.all([loadContentAsync(), displayTimePromise]).then(() => {
  //     setAnimationClass('fadeOut') // Fade out the preloader
  //     setTimeout(() => {
  //       setLoading(false)
  //       setAnimationClass('fadeIn') // Once preloader is hidden, fade in the app content
  //     }, 500) // This timeout should match the fadeOut animation duration
  //   })
  // }, [])

  return (
    <>
      <GlobalStyle />
      <ScrollToTop />

      <Header />

      {/* <TransitionGroup component={null}>
        {loading ? (
          <CSSTransition key='preloader' timeout={500} classNames='fade'>
            <Preloader />
          </CSSTransition>
        ) : (
          <CSSTransition key='app' timeout={500} classNames='fade'>
            <AppContainer>
              <Suspense
                fallback={
                  // <Preloader />
                  ''
                }>
                <Routes>
                  {routeConfig.map(({ path, component: Component, exact }) => (
                    <Route key={path} path={path} element={<Component />} exact={exact} />
                  ))}
                </Routes>
              </Suspense>
            </AppContainer>
          </CSSTransition>
        )}
      </TransitionGroup> */}

      <Suspense fallback={<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }} />}>
        <AppContainer>
          <Routes>
            {routeConfig.map(({ path, component: Component, exact }) => (
              <Route key={path} path={path} element={<Component />} exact={exact} />
            ))}
          </Routes>
        </AppContainer>
      </Suspense>

      <Footer />
    </>
  )
}

export default App
