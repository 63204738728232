import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Hamburger, TopHeader, BottomHeader, HeaderContainer, List, NavLink, CloseIcon, Logo, CTAButton, Menu, Row, RowHideIfMobile } from './Style'
import { headerLinks as defaultHeaderLinks } from './headerConfig'
import { Trans, useTranslation } from 'react-i18next'
import LanguageSwitcher from '../../../components/elements/LanguageSwitcher'
import logo from '../../media/img/logo-white.png'
import logoMobile from '../../media/img/logo-white-mobile.png'
import { useLocalizedPath } from '../../../components/hooks/useLocalizedPath'

export const Header = () => {
  const [menuOpen, setMenuOpen] = useState(false)
  const [visible, setVisible] = useState(true)
  const [headerLinks, setHeaderLinks] = useState(defaultHeaderLinks)

  const { i18n } = useTranslation()
  const { t } = useTranslation('common')

  const navigate = useNavigate()
  const location = useLocation()

  const pathMap = {
    en: {
      '/notre-equipe': '/our-team',
      '/nos-services': '/our-services',
      // Add other mappings as necessary
    },
    fr: {
      '/our-team': '/notre-equipe',
      '/our-services': '/nos-services',
      // Reverse mappings for completeness
    },
  }

  const adjustLinksForLanguage = (links, lang) => {
    return links.map((link) => {
      // Directly return the path for the homepage
      if (link.path === '/') {
        return { ...link, path: lang === 'en' ? '/' : `/${lang}` } // Adjust for non-English home paths
      }

      // Check for a path mapping based on the current language
      const mappedPath = pathMap[lang][link.path]

      // Construct the new path, using the mapped path if available
      const newPath = mappedPath ? `/${lang}${mappedPath}` : `/${lang}${link.path}`

      return { ...link, path: newPath }
    })
  }
  useEffect(() => {
    const currentLang = i18n.language.split('-')[0] // Assuming your language codes are 'en', 'fr', etc.
    const adjustedLinks = adjustLinksForLanguage([...defaultHeaderLinks], currentLang)
    setHeaderLinks(adjustedLinks)
  }, [i18n.language])

  const switchLanguage = (lng) => {
    i18n.changeLanguage(lng).then(() => {
      const pathSegments = location.pathname.split('/').filter(Boolean)
      const newPath = pathSegments.length > 1 ? `/${lng}/${pathSegments.slice(1).join('/')}` : `/${lng}`
      navigate(newPath)
    })
  }

  return (
    <HeaderContainer isVisible={visible}>
      <TopHeader>
        <Logo src={window.innerWidth > 768 ? logo : logoMobile} alt='Logo' onClick={() => navigate('/')} />
        <RowHideIfMobile>
          <CTAButton
            onClick={
              () => navigate(t('nav.ctaButtonLink'))
              // navigate(
              //   headerLinks.find((link) => link.key === 'nav.home')?.path)
            }>
            {t('nav.ctaButtonText')}{' '}
          </CTAButton>
          <LanguageSwitcher onClick={() => switchLanguage('en')} />
        </RowHideIfMobile>
        <Hamburger onClick={() => setMenuOpen(!menuOpen)}>
          <div></div>
          <div></div>
          <div></div>
        </Hamburger>
      </TopHeader>
      <BottomHeader>
        <List>
          {headerLinks.map(({ path, key }) => (
            <li key={path}>
              <NavLink to={path}>
                {key === 'nav.login' ? <i className='fa-solid fa-user'></i> : null} {t(key)}
              </NavLink>
            </li>
          ))}
        </List>
        <span>
          <b>{t('nav.phoneText')}</b> 450-625-7433
        </span>
      </BottomHeader>
      <Menu open={menuOpen}>
        <CloseIcon onClick={() => setMenuOpen(false)}>
          <i className='fa-solid fa-times'></i>
        </CloseIcon>
        <List>
          {headerLinks.map(({ path, key }) => (
            <li key={path}>
              <NavLink to={path} onClick={() => setMenuOpen(false)}>
                {t(key)}
              </NavLink>
            </li>
          ))}
          {window.innerWidth < 768 ? <LanguageSwitcher textColor='black' onClick={() => switchLanguage('en')} /> : null}
        </List>
      </Menu>
    </HeaderContainer>
  )
}
