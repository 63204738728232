import styled, { css } from 'styled-components'

export const GridRow = styled.div.attrs({
  className: 'grid-row',
})`
  display: grid;
  /* grid-template-columns: ${(props) => props.col} */
  /* grid-template-columns: repeat(${(props) => props.col}, minmax(0, 1fr)); */
  grid-template-columns: repeat(${(props) => props.col}, minmax(0, 1fr));
  ${(props) => props.colTemplate && `grid-template-columns: ${props.colTemplate};`}
  grid-gap: ${(props) => props.gridGap || '2rem'};
  margin: ${(props) => props.margin || '2.5rem 0'};
  width: ${(props) => props.width || '100%'};
  height: ${(props) => props.height};
  text-align: ${(props) => props.textAlign};

  ${(props) =>
    props.mobile &&
    css`
      @media (max-width: 50rem) {
        grid-template-columns: 1fr;
      }
    `}
  /* 
  ${(props) =>
    props.layoutReversed &&
    css`
      display: flex;
      flex-wrap: wrap-reverse;
      flex-direction: row-reverse;

      img {
        width: 40%;
      }
      .firstCol {
        width: 40%;
      }
    `} */

    @media screen and (max-width: 768px) {
    ${(props) =>
      props.layoutReversed &&
      css`
        display: flex;
        flex-wrap: wrap-reverse;
        flex-direction: column;

        img {
          width: 100%;
        }
        .firstCol {
          width: 100%;
        }
      `}
  }
`
