import React from 'react'
import styled from 'styled-components'

const StyledButton = styled.button`
  padding: 8px 15px;
  border-radius: 4px;
  font-size: 16px;
  border: 1px solid transparent;
  cursor: pointer;
  transition: background-color 0.3s, border-color 0.3s;
  background: #fafafa;

  &:hover {
    opacity: 0.9;
  }
`

const Button = ({ children, onClick, ...props }) => {
  return (
    <StyledButton onClick={onClick} {...props}>
      {children}
    </StyledButton>
  )
}

export default Button
