import React from 'react'
import PropTypes from 'prop-types'
import { ReactComponent as DefaultSvg } from './default.svg'

// const BaseIcon = ({ size = '24px', color = 'currentColor', children, ...props }) => (
const BaseIcon = ({ size = '24px', color = 'currentColor', children = <DefaultSvg />, ...props }) => ({ children })
// <svg width={size} height={size} viewBox='0 0 24 24' fill='none' stroke={color} xmlns='http://www.w3.org/2000/svg' {...props}>
//   {children}
// </svg>

BaseIcon.propTypes = {
  size: PropTypes.string,
  color: PropTypes.string,
  children: PropTypes.node, // children is required
}

BaseIcon.defaultProps = {
  size: '24px',
  color: 'currentColor',
}

export default BaseIcon

// TYPESCRIPT EXAMPLE

// interface BaseIconProps {
//   size?: string;
//   color?: string;
//   children: React.ReactNode; // children is required
// }

// const BaseIcon: React.FC<BaseIconProps> = ({ size = '24px', color = 'currentColor', children }) => (
//   <svg width={size} height={size} fill={color}>
//     {children}
//   </svg>
// );

// export default BaseIcon
