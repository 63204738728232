import styled from 'styled-components'
import { PageTransitions } from './PageTransitions'

const PageWrapper = styled.div`
  /* max-width: 1200px; */
  width: 100%;
  margin: auto;
`

const WebsiteMainTemplate = ({ children, onSearchSubmit }) => (
  <>
    {/* <Header setMenuOpened={setMenuOpened} /> */}
    {/* <Header /> */}
    <PageTransitions>
      <PageWrapper>{children}</PageWrapper>
    </PageTransitions>
    {/* <Footer /> */}
  </>
)

export default WebsiteMainTemplate
