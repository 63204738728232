import { motion, AnimatePresence } from 'framer-motion'

const pageTransitionVariants = {
  initial: {
    opacity: 0,
    scale: 0.99,
    y: 30,
  },
  enter: {
    opacity: 1,
    scale: 1,
    y: 0,
    transition: {
      duration: 0.3,
      ease: [0.16, 1, 0.3, 1], // Custom cubic bezier easing
      when: 'beforeChildren',
      staggerChildren: 0.1,
    },
  },
  exit: {
    opacity: 0,
    scale: 0.99,
    y: -30,
    transition: {
      duration: 0.4,
      ease: 'easeInOut',
    },
  },
}

const childVariants = {
  initial: {
    opacity: 0,
    y: 20,
  },
  enter: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.4,
      ease: 'easeInOut',
    },
  },
  exit: {
    opacity: 0,
    y: -20,
    transition: {
      duration: 0.3,
      ease: 'easeInOut',
    },
  },
}

export const PageTransitions = ({ children }) => {
  return (
    <AnimatePresence>
      <motion.div variants={pageTransitionVariants} initial='initial' animate='enter' exit='exit' key='page'>
        <motion.div variants={childVariants}>{children}</motion.div>
      </motion.div>
    </AnimatePresence>
  )
}
