import ClientLogin from '../pages/ClientLogin/indexcopy'
import Contact from '../pages/Contact'
import Homepage from '../pages/Home'
import { OurTeam } from '../pages/OurTeam'
import ServicesPage from '../pages/Services'
import { TestPage } from '../pages/Test'

export const routeConfig = [
  { path: '/', component: Homepage, exact: true },
  { path: '/:lang', component: Homepage },
  { path: '/:lang/our-services', component: ServicesPage },
  { path: '/:lang/nos-services', component: ServicesPage },
  { path: '/test', component: TestPage },
  { path: '/:lang/our-team', component: OurTeam },
  { path: '/:lang/notre-equipe', component: OurTeam },
  { path: '/:lang/contact', component: Contact },
  { path: '/:lang/app/login', component: ClientLogin },
  // Add more routes as needed
]
