import React from 'react'
import styled, { css } from 'styled-components'

const AlertContainer = styled.div`
  padding: 15px;
  color: white;
  margin-bottom: 20px;
  ${(props) =>
    props.type === 'success' &&
    css`
      background-color: #28a745;
    `}
`

const Alert = ({ type, children }) => <AlertContainer type={type}>{children}</AlertContainer>

export { Alert }
