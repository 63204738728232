import { ImageAd } from '.'
import { useTranslation } from 'react-i18next'
import img from '../../../app/media/img/fast-service-truck.webp'

export const ImageAd1 = () => {
  const { t } = useTranslation('home')
  return (
    <ImageAd
      src={img}
      // Layout={Layout}
      logo={null}
      title={t('imageAd1.title')}
      description={t('imageAd1.content')}
      left='20%'
      bottom='20%'
      layoutReversed
    />
  )
}
