import { useTranslation } from 'react-i18next'
import { Layout, Title, Subheading, Col } from './Style'
import WebsiteMainTemplate from '../../components/layouts/WebsiteMainTemplate'

export const OurTeam = () => {
  const { t } = useTranslation('ourTeam')

  return (
    <WebsiteMainTemplate>
      <Layout>
        <Col>
          <Title>{t('title')}</Title>
          <Subheading>{t('content')}</Subheading>
        </Col>
      </Layout>
    </WebsiteMainTemplate>
  )
}
