import img from '../../../app/media/img/multi-transport-broker.png'

import { Image, Layout, Text, Slogan } from './Style.js'
import { GridRow } from '../../../components/layouts/GridRow'
import { useTranslation } from 'react-i18next'

export const SloganSection = () => {
  const { t } = useTranslation('home')

  return (
    <Layout>
      <GridRow col='2' mobile>
        <Slogan>
          <Text>
            {t('sloganPart1')}
            <br />
            {t('sloganPart2')}
          </Text>
        </Slogan>
        <Image src={img} alt='hero' data-aos='fade-up' data-aos-duration='1200' />
      </GridRow>
    </Layout>
  )
}
